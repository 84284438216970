<template>
<main>
    <div class="main container">
        <hotel-nav />
        <el-input size="small" type="search" style="width: 200px; margin-left: 40%" v-model="s" ></el-input>
        <el-button icon="el-icon-search" style="margin-left: 10px" size="small" type="primary" @click="search(s)">Search</el-button>
        <br><br>
        <el-table     
          :data="data"     
          
          border
          size="small"
          height="580"
          style="width: 100%;">
            <el-table-column prop="BookingID" label="Booking ID" align="center" min-width="100"></el-table-column>
            <el-table-column prop="AgentId" label="Agent ID" align="center" min-width="100"></el-table-column>
            <el-table-column prop="HotelID" label="Hotel ID" align="center" min-width="100"></el-table-column>
            <el-table-column prop="BookingDate" label="Booking Date" align="center" min-width="100"></el-table-column>
            <el-table-column prop="BookingReferenceNo" label="Booking Reference No" align="center" min-width="100">
              <template slot-scope="scope"> 
                <el-input type="number" size="small" min="0" v-model="scope.row[scope.column.property]" @change="selectid($event,scope.$index)"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="" label="Mail" align="center" min-width="50">
              <template slot-scope="scope"> 
                <el-button size="small" type="primary" icon="el-icon-s-promotion" @click="SelectRow(scope.$index)">Send</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="" label="Detail" align="center" min-width="60">
              <template slot-scope="scope"> 
                <el-button size="small" type="primary" icon="el-icon-s-promotion" @click="dialogTRow(scope.$index)">View Details</el-button>
              </template>
            </el-table-column>
        </el-table>
      <el-dialog title="Booking Detail" :visible.sync="dialogTableVisible" :center="true" width="80%">
          <div style="float: right;">
            <img :src="require(`../assets/${img}`)" width="55" height="50" @click="pdf"/>
          </div>
        <el-table :data="guestdata" max-height="auto" border>
          <el-table-column property="GuestID" label="Guest ID" :min-width="90" align="center"></el-table-column>
          <el-table-column property="BookingID" label="Booking ID" :min-width="100" align="center"></el-table-column>
          <el-table-column property="FirstName" label="First Name" :min-width="170" align="center"></el-table-column>
          <el-table-column property="LastName" label="Last Name" :min-width="170" align="center"></el-table-column>
          <el-table-column property="Gender" label="Gender" :min-width="70" align="center"></el-table-column>
          <el-table-column property="Age" label="Age" :min-width="60" align="center"></el-table-column>
          <el-table-column property="Email" label="Email" :min-width="180" align="center"></el-table-column>
          <el-table-column property="PhoneNo" label="Phone No" :min-width="150" align="center"></el-table-column>
          <el-table-column property="Country" label="Country" :min-width="172" align="center"></el-table-column>
        </el-table>
        <br>
        <hr>
        <el-table :data="roomdata" max-height="auto" border>
          <el-table-column property="hotelid" label="Hotel ID" :min-width="80" align="center"></el-table-column>
          <el-table-column property="bookingid" label="Booking ID" :min-width="100" align="center"></el-table-column>
          <el-table-column property="roomid" label="Room ID" :min-width="80" align="center"></el-table-column>
          <el-table-column property="roomtype" label="Room Type" :min-width="100" align="center"></el-table-column>
          <el-table-column property="RoomQuality" label="Room Quality" :min-width="80" align="center"></el-table-column>
          <el-table-column property="roombasis" label="Room Basis" :min-width="100" align="center"></el-table-column>
          <el-table-column property="RoomQuantity" label="Room Quantity" :min-width="100" align="center"></el-table-column>
          <el-table-column property="price" label="Price" :min-width="80" align="center"></el-table-column>
          <el-table-column property="SpecialPrice" label="SpecialPrice" :min-width="80" align="center"></el-table-column>
          
        </el-table>
      </el-dialog>
    </div>
</main>
</template>

<script>
import axios from "axios";
import HotelNav from '../components/HotelNav.vue';
export default {
   components: { HotelNav },
    name: "Admin", 
    data() {
        return {
            data: [],
            guestdata: [],
            roomdata: [],
            s: "",
            bri: "",
            bookingid: 0,
            bookingrefno: 0,
            input_i: 0,
            email: "",
            dialogTableVisible: false,
            img: "pdf-icon-11.jpg",
    };
  },
  methods: {
    async postEmail(){
      //console.log(this.bookingid);
      try {
        await axios.post("https://www.desertlinktours.com/api/hotels/FinalMail", {
          name: this.bookingid , email: this.email
        });
        } catch (err) {
        console.log(err);
        }
    },
    async getBooking() {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/Booking`
            );
            this.data = response.data;
            //console.log(response.data);
            //console.log(this.item);
           
        } catch (err) {
        console.log(err);
        }
    },
    async getBookingID(s) {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/Booking/${s}`
            );
            this.data = response.data;
            //console.log(response.data);
            //console.log(this.item);
        } catch (err) {
        console.log(err);
        }
    },
    async getGuestDetail(s) {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/GuestDetail/${s}`
            );
            this.guestdata = response.data;
            //console.log(response.data);
            //console.log(this.item);
        } catch (err) {
        console.log(err);
        }
    },
    async getRoomDetail(s) {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/RoomDetail/${s}`
            );
            this.roomdata = response.data;
            //console.log(response.data);
            //console.log(this.item);
        } catch (err) {
        console.log(err);
        }
    },
    async getMailInfo(s) {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/FinalMailInfo/${s}`
            );
            this.email = response.data[0].email;
            //console.log(this.email);
            //console.log(this.item);
        } catch (err) {
        console.log(err);
        }
    },
  search(s)
  {
    //this.$router.push('/booking');
    this.getBookingID(s);
  },
  selectid(e,i){
    //console.log(e.target.value);
    this.input_i=i;
    this.bookingrefno=e;  
  },
  SelectRow(e) {
    //console.log(index);
    //console.log(e);
    //console.log(this.data[e].BookingID);
    this.bookingid=this.data[e].BookingID;
    //console.log(this.bookingid);
    //console.log(this.bookingrefno);
    if(this.input_i==e){
      //console.log(this.input_i,e);
      if (this.bookingrefno==0 ){
      this.$message({
            type: 'error',
            message: 'Booking Reference No is Empty'
          });
          }
          //console.log("null");
      else{
      this.BookingReferenceNo();
      this.getMailInfo(this.bookingid);
      this.postEmail();
            this.$message({
            type: 'success',
            message: 'Saved and Send'
          });
      this.getBooking();
      this.getBooking();
      this.bookingrefno=0;
      //this.getMailInfo(this.bookingid);  
      //this.postEmail();
      }
    }
    else{
      //console.log("wrong");
      this.$message({
            type: 'error',
            message: 'Booking Reference No is Empty'
          });
    }
    //BookingReferenceNo(e);
  },
  dialogTRow(index){
    this.dialogTableVisible=true;
    //this.data[index].BookingID;
    this.bookingid=this.data[index].BookingID;
    this.getGuestDetail(this.data[index].BookingID);
    this.getRoomDetail(this.data[index].BookingID);
    
  },
  pdf(){
    this.$router.push(`/booking/${this.bookingid}`);
  },
  async BookingReferenceNo() {
            try {
            await axios.post(
           `https://www.desertlinktours.com/api/hotels/BookingReferenceNo`, {
            BRN: this.bookingrefno, BI: this.bookingid
          });
            //this.data = response.data;
            //console.log(this.name.split("-").join(" "));
           // for(let i=0 ;i<=this.da.length;i++) {
            //  this.dat.push(this.da[i]["HotelName"],this.da[i]["Location"],this.da[i]["city"],this.da[i]["district"],this.da[i]["country"]);
            //}
            //console.log(this.dat);
            //console.log(this.da);

        } catch (err) {
        console.log(err);
        }},
},
  created() {
    this.getBooking();
  },
}
</script>

<style scoped>
.main{
    padding: 20px 0;
    min-height: 100vh;
}
button {
  border: none;
  outline: none;
  color: white;
  background-color: teal;
}
button:hover {
  background-color: #E4A700 ;
}
</style>

<style>
  /*Modify Table background color and text colors*/
  .el-table da, .el-table th.is-leaf,.el-table--border, .el-table--group{
    background-color: #20c997;
    color: black;
    font-size: 14px;
    flex: auto;
  }
  /*Modify the highlight of the Element-UI's Table in the mouse hover HOVER*/
  .el-table tbody tr:hover>td {
    background-color:#e6fff8; 
  }
</style>